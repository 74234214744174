import { ComponentsMapping, Edge } from "constants/types";
import dynamic from "next/dynamic";

export const getEdgeSettings = ({ edge_border_top, edge_border_bottom }) => {
  return `${edge_border_top ? "top" : ""}${
    edge_border_top && edge_border_bottom ? "-" : ""
  }${edge_border_bottom ? "bottom" : ""}` as Edge;
};

const componentsMapping: ComponentsMapping = {
  default: dynamic(() => import("components/wrapper/components/default")),
  facts: dynamic(() => import("components/wrapper/components/facts")),
  slider: dynamic(() => import("components/wrapper/components/slider")),
  content_banner: dynamic(
    () => import("components/wrapper/components/content_banner"),
  ),
  content_set: dynamic(
    () => import("components/wrapper/components/contentset"),
  ),
  navigation: dynamic(() => import("components/wrapper/components/navigation")),
  image_text: dynamic(() => import("components/wrapper/components/imagetext")),
  media_text: dynamic(() => import("components/wrapper/components/mediatext")),
  iframe: dynamic(() => import("components/wrapper/components/iframe")),
  images: dynamic(() => import("components/wrapper/components/images")),
  image_map: dynamic(() => import("components/wrapper/components/image_map")),
  quotes: dynamic(() => import("components/wrapper/components/quotes")),
  styled_list: dynamic(
    () => import("components/wrapper/components/styledlist"),
  ),
  text: dynamic(() => import("components/wrapper/components/text")),
  tabs: dynamic(() => import("components/wrapper/components/tabs")),
  grid: dynamic(() => import("components/wrapper/components/grid")),
  grid_element: dynamic(() => import("components/wrapper/components/grid")),
  contact: dynamic(() => import("components/wrapper/components/contact")),
  forms: dynamic(() => import("components/wrapper/components/forms")),
  pick_entity: dynamic(() =>
    import("components/wrapper/components/pick").then(
      (module) => module.PickEntityWrapper,
    ),
  ),
  pick_image: dynamic(() =>
    import("components/wrapper/components/pick").then(
      (module) => module.PickImageWrapper,
    ),
  ),
  pick_textblock: dynamic(() =>
    import("components/wrapper/components/pick").then(
      (module) => module.PickTextblockWrapper,
    ),
  ),
  video: dynamic(() => import("components/wrapper/components/video")),
  video_map: dynamic(() => import("components/wrapper/components/videomap")),
  history: dynamic(() => import("components/wrapper/components/history")),
  accordion: dynamic(() => import("components/wrapper/components/accordion")),
};

export default componentsMapping;
