import { colors as themeColors } from "theme/theme";
import { PaginationOptions, Swiper as SwiperType } from "swiper/types";
import { Shape } from "components/shape";
import { SliderProps } from "constants/types";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";
import Slide from "./slide";
import SliderNavButton from "./slider-nav-button";

import {
  CSSProperties,
  Children,
  FC,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Autoplay,
  Controller,
  EffectFade,
  Navigation,
  Pagination,
  Thumbs,
  Virtual,
} from "swiper/modules";

export const Slider: FC<SliderProps> = ({
  autoplay = false,
  breakpoints = null,
  children,
  className = "",
  colors = [],
  fade = false,
  fixHeightMode = null,
  autoheight = false,
  forceSliderForSingleSlides = false,
  indicators = [],
  loop = false,
  navigation = null,
  navigationOnHover = false,
  navNextButton,
  navPrevButton,
  onChange,
  onLoaded,
  pagination = null,
  paginationRef = null,
  preview = false,
  slidesPerGroup = null,
  slidesPerView = null,
  spaceBetween = 0,
  swiperRef = null,
  onSwiper,
  thumbs = null,
  updater,
  virtual = true,
}) => {
  const [swiper, setSwiper] = useState<SwiperType | null>(null);

  const defaultPaginationRef = useRef<HTMLDivElement>(null);
  const indicatorWrapper = useRef<HTMLUListElement>(null);
  const navPrevButtonRef = useRef<HTMLButtonElement>(null);
  const navNextButtonRef = useRef<HTMLButtonElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const paginationWrapper = paginationRef ?? defaultPaginationRef;

  const isMobile = useMediaQuery({ maxWidth: 640 });

  const [activeColor, setActiveColor] = useState("");

  const customNavigation = ["outer", "inset", "container"].includes(navigation);

  const previewValue = useMemo(() => {
    if (preview === true) return "20%";
    return isMobile ? false : preview;
  }, [preview, isMobile]);

  const isLoopEnabled = useMemo(() => {
    // condition adapted from swiper documentation for loop prop (https://swiperjs.com/swiper-api#param-loop)
    return loop && slidesPerView + slidesPerGroup <= Children.count(children);
  }, [loop, slidesPerView, slidesPerGroup, children]);

  const paginationOptions: PaginationOptions = useMemo(() => {
    if (!swiper) return;

    if (
      indicators.length > 0 &&
      (swiper.allowSlidePrev || swiper.allowSlideNext)
    ) {
      return {
        clickable: true,
        el: indicatorWrapper.current,
        renderBullet: (index, className) =>
          `<li class="${className}">${indicators[index]}</li>`,
      };
    }

    return {
      type: "fraction",
      el: paginationWrapper.current,
      formatFractionCurrent: (number) => number,
      formatFractionTotal: (number) => number,
      renderFraction: (currentClass, totalClass) =>
        `<span class="${currentClass}"></span> / <span class="${totalClass}"></span>`,
    };
  }, [indicators, paginationWrapper, swiper]);

  const slideChangeHandler = useCallback(() => {
    const index: number = swiper?.realIndex ?? 0;

    thumbs && thumbs?.swiper?.slideTo(index);

    onChange?.(index);

    if (!indicatorWrapper.current) return;
    let activeInPast = false;
    Array.from(indicatorWrapper.current?.children).forEach((item) => {
      activeInPast =
        item?.classList.contains("swiper-pagination-bullet-active") ||
        activeInPast;
      item?.classList[activeInPast ? "remove" : "add"]("preActive");
    });
  }, [onChange, swiper, thumbs?.swiper]);

  const [isSwipeable, setIsSwipeable] = useState(false);

  const swiperUpdate = useCallback(() => {
    if (!swiper) return;

    if (pagination && isSwipeable && swiper.pagination) {
      swiper.pagination.destroy();
      swiper.params.pagination = {
        ...(typeof swiper.params.pagination === "object"
          ? swiper.params.pagination
          : {}),
        ...paginationOptions,
      };
      swiper.pagination.init();
      swiper.pagination.render();
      swiper.pagination.update();
    }

    if (navNextButtonRef.current && navPrevButtonRef.current) {
      swiper.navigation = {
        ...swiper.navigation,
        prevEl: navPrevButtonRef.current,
        nextEl: navNextButtonRef.current,
      };
    }

    if (autoplay && swiper.autoplay) {
      wrapperRef.current?.style.setProperty(
        "--autoplay-delay",
        typeof autoplay === "object" ? `${autoplay?.delay + 250}ms` : "3000ms",
      );
      swiper.autoplay.start();
      slideChangeHandler();
    }

    setTimeout(() => {
      swiper.update();
      setIsSwipeable(Boolean(swiper?.allowSlideNext || swiper?.allowSlidePrev));
    }, 200);
  }, [
    autoplay,
    pagination,
    isSwipeable,
    paginationOptions,
    swiper,
    slideChangeHandler,
  ]);

  useEffect(() => {
    if (autoplay && autoplay.pauseOnMouseEnter) {
      const mouseHandler =
        (enter = true) =>
        () => {
          wrapperRef.current.classList[enter ? "add" : "remove"](
            "slider--paused",
          );
          swiper?.autoplay[enter ? "stop" : "start"]();
        };
      const mouseHandlers = {
        enter: mouseHandler(true),
        leave: mouseHandler(false),
      };

      wrapperRef.current?.addEventListener("mouseenter", mouseHandlers.enter);
      wrapperRef.current?.addEventListener("mouseleave", mouseHandlers.leave);

      const tempWrapper = wrapperRef.current;
      return () => {
        tempWrapper?.removeEventListener("mouseenter", mouseHandlers.enter);
        tempWrapper?.removeEventListener("mouseleave", mouseHandlers.leave);
      };
    }
  }, [autoplay, wrapperRef, swiper]);

  useEffect(() => {
    swiperUpdate();
  }, [
    indicatorWrapper,
    navNextButtonRef,
    navPrevButtonRef,
    paginationWrapper,
    swiper,
    swiperUpdate,
    updater,
  ]);

  const [virtualSlidesEnabled, setVirtualSlidesEnabled] = useState(false);

  useEffect(() => {
    if (!thumbs) {
      // deactivate virtual slider for now, because it does not really work
      // setVirtualSlidesEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return Children.count(children) <= 1 && !forceSliderForSingleSlides ? (
    <div
      className={classNames(className, "d-flex", "flex-column", "static-image")}
    >
      {children}
    </div>
  ) : (
    <div
      className={classNames(
        "slider",
        navigationOnHover && "slider--navigationOnHover",
        autoplay && "slider--autoplay",
        autoplay && autoplay?.pauseOnMouseEnter && "slider--pauseOnMouseEnter",
        className,
        activeColor,
        navigation && !customNavigation && "slider--default__navigation",
      )}
      ref={wrapperRef}
    >
      {previewValue && <div className="slider__preview" />}
      <Swiper
        modules={[
          Autoplay,
          EffectFade,
          Pagination,
          Navigation,
          Thumbs,
          Virtual,
        ]}
        ref={swiperRef}
        onSwiper={(swiper) => {
          setSwiper(swiper);
          onSwiper?.(swiper);
        }}
        className={classNames(fixHeightMode && "slider--fixHeightMode")}
        style={
          fixHeightMode
            ? ({ "--fixHeight": `${fixHeightMode}px` } as CSSProperties)
            : undefined
        }
        autoplay={autoplay && { ...autoplay, pauseOnMouseEnter: false }}
        autoHeight={autoheight}
        preventClicks={false}
        preventClicksPropagation={false}
        simulateTouch
        loop={isLoopEnabled}
        onSlideChange={slideChangeHandler}
        slidesPerGroup={
          !fixHeightMode && slidesPerView ? slidesPerGroup || slidesPerView : 1
        }
        slidesPerView={
          fixHeightMode ? "auto" : slidesPerView ? slidesPerView : 1
        }
        height={fixHeightMode || null}
        spaceBetween={spaceBetween}
        pagination={pagination && isSwipeable ? paginationOptions : undefined}
        breakpoints={breakpoints}
        thumbs={thumbs}
        navigation={
          navigation || (navPrevButton && navNextButton)
            ? {
                prevEl: navigation
                  ? navPrevButtonRef.current
                  : navPrevButton?.current,
                nextEl: navigation
                  ? navNextButtonRef.current
                  : navNextButton?.current,
              }
            : { enabled: false }
        }
        effect={fade ? "fade" : "slide"}
        onInit={onLoaded}
        watchSlidesProgress
        virtual={
          virtual && !fixHeightMode && virtualSlidesEnabled
            ? {
                enabled: true,
                addSlidesAfter: (slidesPerView || 0) - 1,
              }
            : false
        }
        key={virtualSlidesEnabled ? "virtual" : "non-virtual"} // key required to change to produce a re-render of the swiper
      >
        {Children.map(children, (child, index) => (
          <SwiperSlide
            style={
              index <= Children.count(children) - 1 && previewValue
                ? { width: `calc(100% - ${previewValue})` }
                : {}
            }
            key={child.key || index}
            virtualIndex={index}
          >
            <Slide updateColor={() => setActiveColor(colors[index])}>
              {child}
            </Slide>
          </SwiperSlide>
        ))}
      </Swiper>
      {pagination && indicators.length > 0 && (
        <div className="slider__indicators">
          <ul ref={indicatorWrapper} className="slider__indicators__list"></ul>
        </div>
      )}
      {pagination && paginationRef === null && indicators.length === 0 && (
        <div className="slider__pagination" ref={paginationWrapper}></div>
      )}
      {navigation && customNavigation && isSwipeable && (
        <div
          className={classNames(
            "slider__navigation",
            "mt-3",
            navigation === "inset" && "slider__navigation--inset",
            navigation === "container" && "container",
            activeColor,
          )}
        >
          <SliderNavButton
            ref={navPrevButtonRef}
            role="prev"
            inset={navigation === "inset"}
            onClick={() => swiper?.slidePrev()}
          />
          <SliderNavButton
            ref={navNextButtonRef}
            role="next"
            inset={navigation === "inset"}
            onClick={() => swiper?.slideNext()}
          />
        </div>
      )}
      {navigation && !customNavigation && (
        <>
          <SliderNavButton
            ref={navPrevButtonRef}
            className={classNames(
              !isSwipeable && "slider__prevButton--hidden",
              !loop && swiper?.realIndex === 0 && "swiper-button-disabled",
            )}
            role="prev"
            inset={navigation === "inset"}
            size={36}
          />
          <SliderNavButton
            ref={navNextButtonRef}
            className={classNames(!isSwipeable && "slider__nextButton--hidden")}
            role="next"
            inset={navigation === "inset"}
            size={36}
          />
        </>
      )}
    </div>
  );
};

interface SliderThumbnailsProps {
  children: ReactNode;
  className?: string;
  navNextButton?: MutableRefObject<HTMLButtonElement>;
  navPrevButton?: MutableRefObject<HTMLButtonElement>;
  onClick?: () => void;
  setThumbsSwiper: (swiper: SwiperClass) => void;
}

export const SliderThumbnails: FC<SliderThumbnailsProps> = ({
  children,
  className,
  navNextButton,
  navPrevButton,
  onClick,
  setThumbsSwiper,
}) => {
  const internalNavPrevButtonRef = useRef<HTMLButtonElement>(null);
  const internalNavNextButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <button
        ref={navPrevButton || internalNavNextButtonRef}
        className={classNames("slider__prevButton")}
      >
        <Shape size={18} variant={"caret-left"} fill={themeColors.darkBlue} />
      </button>
      <Swiper
        className={className}
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={3}
        centeredSlides
        centerInsufficientSlides
        centeredSlidesBounds
        watchSlidesProgress
        onClick={onClick}
        onBeforeDestroy={() => {
          setThumbsSwiper(null);
        }}
        navigation={
          !navPrevButton &&
          !navNextButton && {
            prevEl: internalNavPrevButtonRef.current,
            nextEl: internalNavNextButtonRef.current,
          }
        }
        modules={[Navigation, Thumbs, Controller]}
      >
        {Children.map(children, (child, index) => {
          return (
            <SwiperSlide key={index} virtualIndex={index}>
              {child}
            </SwiperSlide>
          );
        })}
      </Swiper>
      <button
        ref={navNextButton || internalNavNextButtonRef}
        className={classNames("slider__nextButton")}
      >
        <Shape size={18} variant={"caret-right"} fill={themeColors.darkBlue} />
      </button>
    </>
  );
};
