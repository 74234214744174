import { Button } from "components/button";
import { Container } from "components/container";
import { Heading } from "components/heading";
import { List } from "components/lists";
import { Shape } from "components/shape";
import { getEdgeSettings } from "components/wrapper/components";
import DefaultComponent from "components/wrapper/components/default";
import HeadingWrapper from "components/wrapper/components/heading";
import ImagesWrapper from "components/wrapper/components/images";
import { headerLevel, WrapperComponentProps } from "constants/types";
import { useFormContext } from "contexts/form-context";
import { getSpeakingId } from "helpers/text-processing";
import { CSSProperties, FC, Fragment } from "react";

interface TableOptionsProps {
  colWidth?: string;
  enableTableSettings: boolean;
}

interface TipTapComponentProps {
  listType?: string;
  tableOptions?: TableOptionsProps;
  tree: Array<{
    attrs?: Record<string, unknown>;
    content?: any[];
    type: string;
  }>;
}
export const TipTapComponent: FC<TipTapComponentProps> = ({
  tree,
  listType = "",
  tableOptions,
}) => {
  return (
    <>
      {Array.isArray(tree) &&
        tree.map((element, index) => {
          const TagName =
            components[element.type || "default"] ?? components.default;
          const isTableCell = element.type === "table_cell";
          const isTable = element.type === "table";
          const isTableRow = element.type === "table_row";

          return (
            <TagName
              {...element.attrs}
              listType={listType}
              type={element.type}
              key={element.type + "-" + index}
              {...((isTable || isTableCell || isTableRow) && {
                isFirstCell: index === 0,
                tableOptions: tableOptions,
              })}
            >
              {element.content ? element.content : null}
            </TagName>
          );
        })}
    </>
  );
};

const ButtonWrapper = ({
  color,
  text,
  link,
  blank,
  open_service_form,
  ...props
}) => {
  const { setServiceFormOpen } = useFormContext();
  return (
    <Button
      variant={color}
      label={text}
      url={link}
      target={blank ? "_blank" : "_self"}
      onClick={open_service_form ? () => setServiceFormOpen(true) : undefined}
      {...props}
    />
  );
};

const set_components = {
  default: DefaultComponent,
  button: ButtonWrapper,
  image: ImagesWrapper,
};

const Set = ({ values }) => {
  const TagName =
    set_components[values.type || "default"] ?? set_components.default;
  return <TagName {...values} />;
};

const Header = (props) => {
  // TODO: what about italic/bold inside?
  return (
    <Heading
      level={`h${props.level}` as headerLevel}
      title={props?.children?.map((child) => child?.text).join("")}
    />
  );
};

const Paragraph = (props) => {
  return (
    <p {...props.attrs}>
      {props.children &&
        props.children.map((child, index) => {
          const isLink =
            child.marks && child.marks.some((mark) => mark.type === "link");

          if (isLink) {
            const mark = child.marks.find((mark) => mark.type === "link");

            return (
              <a
                className={`${
                  child.marks
                    ? child.marks.map((mark) => mark.type).join(" ")
                    : ""
                }`}
                key={index}
                {...mark.attrs}
              >
                {child.text}
              </a>
            );
          }

          switch (child.type) {
            case "text":
              return child.marks ? (
                <span
                  className={`${child.marks
                    .map((mark) => mark.type)
                    .join(" ")}`}
                  key={index}
                >
                  {child.text}
                </span>
              ) : (
                <Fragment key={index}>{child.text}</Fragment>
              );
            case "hard_break":
              return <br key={index} />;
            default:
              return null;
          }
        })}
    </p>
  );
};

const Table = (props) => {
  return (
    <table className="table table-striped">
      <tbody>
        <TipTapComponent
          tree={props.children}
          tableOptions={props.tableOptions}
        />
      </tbody>
    </table>
  );
};

const TableRow = (props) => {
  return (
    <tr>
      <TipTapComponent
        tree={props.children}
        tableOptions={props.tableOptions}
      />
    </tr>
  );
};

interface TableCellProps {
  background?: string;
  children?: any[];
  colspan?: number;
  colwidth?: number;
  isFirstCell: boolean;
  listType: string;
  rowspan: number;
  tableOptions: TableOptionsProps;
  type: string;
}

export const TableCell: FC<TableCellProps> = (props) => {
  const { colWidth, enableTableSettings } = props.tableOptions ?? {};

  const style: CSSProperties | undefined =
    enableTableSettings && colWidth?.trim()
      ? ({ "--col-width": `${colWidth}%` } as CSSProperties)
      : undefined;

  return (
    <td
      style={style}
      className={props.isFirstCell ? "first-cell" : ""}
      colSpan={props.colspan > 1 ? props.colspan : undefined}
      rowSpan={props.rowspan > 1 ? props.rowspan : undefined}
    >
      <TipTapComponent
        tree={props.children}
        tableOptions={props.tableOptions}
      />
    </td>
  );
};

const TableHeader = (props) => {
  return (
    <th
      colSpan={props.colspan > 1 ? props.colspan : undefined}
      rowSpan={props.rowspan > 1 ? props.rowspan : undefined}
    >
      <TipTapComponent
        tree={props.children}
        tableOptions={props.tableOptions}
      />
    </th>
  );
};

const Blockquote = (props) => {
  return (
    <blockquote className="text-blockquote">
      <TipTapComponent tree={props.children} />
    </blockquote>
  );
};

const BulletList = (props) => {
  return (
    <List
      variant={
        props.listType === "white-check" ? "check-list" : "styled-list-wrapper"
      }
    >
      <TipTapComponent tree={props.children} listType={props.listType} />
    </List>
  );
};

const OrderedList = (props) => {
  return (
    <ol>
      <TipTapComponent tree={props.children} />
    </ol>
  );
};

const ListItem = (props) => {
  return (
    <li>
      {!!(props.listType === "white-check") && (
        <Shape fill="#fff" variant="check" className="ml-0" />
      )}
      <TipTapComponent tree={props.children} />
    </li>
  );
};

const components = {
  default: DefaultComponent,
  heading: Header,
  table: Table,
  table_row: TableRow,
  table_cell: TableCell,
  table_header: TableHeader,
  paragraph: Paragraph,
  blockquote: Blockquote,
  ordered_list: OrderedList,
  list_item: ListItem,
  bullet_list: BulletList,
  set: Set,
};

interface TextWrapperProps extends WrapperComponentProps {
  col_width?: string;
  enable_table_settings: boolean;
  rich_text?: any[];
}

const TextWrapper: FC<TextWrapperProps> = (props) => {
  const { rich_text, col_width, enable_table_settings } = props;
  const anchorNavId =
    props.anchor_navigation && props.anchor_navigation_title
      ? getSpeakingId({ title: props.anchor_navigation_title })
      : null;

  const tableProps = {
    colWidth: col_width,
    enableTableSettings: enable_table_settings,
  };

  return (
    <Container
      edge={getEdgeSettings(props)}
      marginTop={props.margin_top}
      marginBottom={props.margin_bottom}
      background={props.background_color}
      layout={props.header_layout}
      id={props.custom_id || anchorNavId || `component__text-${props.index}`}
      className="section component__text styled-list-wrapper"
    >
      <HeadingWrapper {...props} />
      <TipTapComponent tree={rich_text} tableOptions={tableProps} />
    </Container>
  );
};

export default TextWrapper;
