import { Button } from "components/button";
import { Cloudinary, CloudinaryVideo } from "components/cloudinary";
import { Col, Container as BootstrapContainer, Row } from "react-bootstrap";
import { FC, useState, memo } from "react";
import { getSpeakingId, sanitize } from "helpers/text-processing";
import { HeroSlideProps, Media } from "constants/types";
import { TipTapComponent } from "components/wrapper/components/text";
import classNames from "classnames";
import { Lightbox } from "components/lightbox";
import { Shape } from "components/shape";
import { Container } from "components/container";
import { useLabels } from "helpers/hooks";

const HeroSlide: FC<HeroSlideProps> = memo(({
  headline,
  headlineStylingLevel,
  color,
  subline,
  eye_catcher,
  text,
  buttons = [],
  short_label,
  image,
  priority = null,
}) => {
  const [videoBtn] = useLabels(["ui-872", "Play video"]);
  const [open, setOpen] = useState(false);

  const isVideo = image?.__typename === "Vi";

  const ButtonColorMapping = {
    blue: "primary",
    orange: "secondary",
    grey: "tertiary",
    white: "white",
    green: "white",
  };

  const videoButton =
  {
    color: ButtonColorMapping[color],
    link: null,
    text: videoBtn.label,
  };

  const ImageLightbox = ({ media }) => {
    return (
      <Lightbox
        heading={{
          title: media?.title,
          stylingLevel: "h4",
        }}
        headingType="primary"
        background="grey-triangle"
        size="large"
        open={open}
        setOpen={setOpen}
      >
        {isVideo ? (
          <Container>
            <CloudinaryVideo media={media as Media} />
          </Container>
        ) : (
          <Cloudinary media={media as Media} />
        )}
      </Lightbox>
    );
  };

  const SlideMedia =
    ({ media }) => {
      return (
        <>
          {isVideo ? (
            <div className="lightbox-video">
              <Cloudinary media={media} />
              <Shape
                variant="play-circle"
                fill="white"
                size={54}
                onClick={() => {
                  setOpen(true);
                }}
              />
            </div>
          ) : (
            <Cloudinary media={media} />
          )}
        </>
      );
    }

  return (
    <div className={classNames("slide", color && `slide--${color}`)}>
      <div className="slide__content-wrapper">
        <BootstrapContainer>
          <Row>
            <Col lg={5}>
              <div className="slide__contentArea__content">
                <div
                  className="slide__contentArea__content__subline mb-1"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(subline),
                  }}
                />
                <h2
                  className={classNames(
                    "slide__contentArea__content__headline",
                    headlineStylingLevel ? `h${headlineStylingLevel}` : "h3",
                  )}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(headline),
                  }}
                />
                <div className="slide__contentArea__content__text">
                  <TipTapComponent tree={text} />
                </div>
                <div className="slide__contentArea__content__buttons">
                  {Array.isArray(buttons) &&
                    buttons.length !== 0 &&
                    buttons
                      .filter(
                        (button) => button && Object.keys(button).length !== 0,
                      )
                      .map((button, index) => (
                        <Button
                          {...button}
                          url={button.link}
                          label={button.text}
                          variant={button.color}
                          key={`slider-button-${index}`}
                          onClick={() => {
                            window?._etracker?.sendEvent(
                              // @ts-ignore
                              new et_UserDefinedEvent(
                                getSpeakingId({ title: short_label }),
                                "Link",
                                "click",
                                "Banner",
                              ),
                            );
                          }}
                        />
                      ))}
                  {isVideo && (
                    <Button
                      {...videoButton}
                      label={videoButton.text}
                      onClick={() => {
                        window?._etracker?.sendEvent(
                          // @ts-ignore
                          new et_UserDefinedEvent(
                            getSpeakingId({ title: short_label }),
                            "Link",
                            "click",
                            "Banner",
                          ),
                        );
                        setOpen(true);
                      }}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </BootstrapContainer>
      </div>
      <div className="slide__imageArea">
        <div className="slide__imageArea__background eye-catcher-container">
          {eye_catcher && (
            <div className="mt-2 ml-3 position-absolute eye-catcher">
              {eye_catcher}
            </div>
          )}
        </div>
        <div className="slide__imageArea__image">
          <SlideMedia media={image as Media} />
        </div>
      </div>
      {image && <ImageLightbox media={image as Media} />}
    </div>
  );
});

HeroSlide.displayName = "HeroSlide";

export default HeroSlide;
